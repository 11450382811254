import * as yup from "yup";
import "yup-phone";

const appointmentValidation = yup.object().shape({
  firstname: yup.string().required("This field is required"),
  lastname: yup.string().required("This field is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("This field is required"),
  phone: yup.string().min(10, "Phone number must be 10 digits long"),
  fileInputs: yup
    .array()
    .max(5, "Maximum number of images is 5"),
  remarks: yup.string(),
});

export default appointmentValidation;
