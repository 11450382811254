import "./header.scss";
import logo from "../../assets/icons/logo.png";
import { useWindowSize } from "@uidotdev/usehooks";
import sandwich from "../../assets/icons/menu.png";
import close from "../../assets/icons/close.png";
import { OutsideClick } from "../../hooks/outsideClick";
import { useState, useRef } from "react";
import { Link } from "react-scroll";

const Header = () => {
  const sandwichRef = useRef(null);
  const smallNavRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = (e) => {
    e.preventDefault();
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  OutsideClick(sandwichRef, smallNavRef, closeMenu);

  const size = useWindowSize();
  return (
    <header>
      <div className="logo-container">
        <img src={logo} alt="logo-png" />
      </div>
      {size.width <= 767 ? (
        <div className="navigation-small-screen" ref={smallNavRef}>
          {menuOpen ? (
            <div className="sandwich-button" onClick={closeMenu}>
              <img src={close} alt="sandwich" />
            </div>
          ) : (
            <div className="sandwich-button" onClick={handleMenuOpen}>
              <img src={sandwich} alt="sandwich" />
            </div>
          )}
          <div
            className={
              menuOpen
                ? "sandwich-navigator-container open"
                : "sandwich-navigator-container"
            }
            ref={sandwichRef}
          >
            <ul>
              <li>
                <Link to="home" spy={true} smooth={true}>Home</Link>
              </li>
              <li>
                <Link to="services" spy={true} smooth={true}>Services</Link>
              </li>
              <li>
                <Link to="about" spy={true} smooth={true}>About</Link>
              </li>
              <li>
                <Link to="footer" spy={true} smooth={true}>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className="navigation-container">
          <ul>
          <li>
                <Link to="home" spy={true} smooth={true}>Home</Link>
              </li>
              <li>
                <Link to="services" spy={true} smooth={true}>Services</Link>
              </li>
              <li>
                <Link to="about" spy={true} smooth={true}>About</Link>
              </li>
              <li>
                <Link to="footer" spy={true} smooth={true}>Contact Us</Link>
              </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
