import "./servicetag.scss";
import { useState, useEffect } from "react";

export const ServiceTag = ({
  service,
  isActive,
  setChosenServices,
  servicesDetails,
  availableTime,
  disabled,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const handleTagClick = () => {
    if (isActive) {
      setChosenServices((prev) => {
        return prev.filter((s) => s !== service.id);
      });
    } else {
      setChosenServices((prev) => {
        return [...prev, service.id];
      });
    }
  };

  useEffect(() => {
    if (servicesDetails.duration + service.duration > availableTime) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [servicesDetails, availableTime]);

  return (
    <div
      className={
        isActive
          ? "servicetag-container servicetag-active"
          : isDisabled || disabled
          ? "servicetag-container disabled"
          : "servicetag-container"
      }
      onClick={handleTagClick}
    >
      <p className="servicetag-name">{service.name}</p>
    </div>
  );
};

export default ServiceTag;
