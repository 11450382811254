import React from "react";
import "./failalert.scss";
import { MdClose } from "react-icons/md";

const FailAlert = ({ message, dismissed, setDismissed }) => {
  const handleBackdropClick = () => {
    setDismissed(true);
  };

  const handleCloseClick = () => {
    setDismissed(true);
  };

  return !dismissed ? (
    <div className="failed-alert" onClick={handleBackdropClick}>
      <MdClose className="close-icon" onClick={handleCloseClick} />
      <p>{message}</p>
      <button className="dismiss-button" onClick={handleCloseClick}>
        Dismiss
      </button>
    </div>
  ) : null;
};

export default FailAlert;
