import { useState, useEffect } from "react";
import "./calendar.scss";
import logo from "../../assets/icons/logo.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment-timezone";
import { addEvent, fetchAvailableSlots } from "../../api/calendarAPI";
import Timeslot from "../../components/timeslot/Timeslot";
import ReactLoading from "react-loading";
import { useFormik } from "formik";
import appointmentValidation from "../../validations/appointment";
import { FaArrowLeft } from "react-icons/fa";
import { PatternFormat } from "react-number-format";
import { RiCloseCircleFill } from "react-icons/ri";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { CiClock2 } from "react-icons/ci";
import { LiaGlobeAmericasSolid } from "react-icons/lia";
import { GiDuration } from "react-icons/gi";
import { IoPricetagOutline } from "react-icons/io5";
import services from "../../models/services.json";
import ServiceTag from "../../components/service-tag/ServiceTag";
import SuccessAlert from "../../components/success-alert/SuccessAlert";
import FailAlert from "../../components/fail-alert/FailAlert";

const CalendarScreen = () => {
  const [responseStatus, setResponseStatus] = useState("success");
  const [alertDismissed, setAlertDismissed] = useState(true);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [activeDate, setActiveDate] = useState(null);
  const [confirmedTimeslot, setConfirmedTimeslot] = useState(null);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState(null);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chosenServices, setChosenServices] = useState([]);
  const [servicesDetails, setServicesDetails] = useState({
    duration: 0,
    price: 0,
    services: [],
  });
  const [availableTime, setAvailableTime] = useState(0);

  useEffect(() => {
    let duration = 0;
    let price = 0;
    const localServices = [];

    for (let i = 0; i < chosenServices.length; i++) {
      const service = services.find((ser) => ser.id === chosenServices[i]);
      duration += service.duration;
      price += service.price;
      localServices.push(service.name);
    }

    setServicesDetails({
      duration: duration,
      price: price,
      services: localServices,
    });
  }, [chosenServices, setChosenServices]);

  const handleSendEmail = async (event) => {
    try {
      const formData = new FormData();
      formData.append("firstname", event.firstname);
      formData.append("lastname", event.lastname);
      formData.append("phone", event.phone);
      formData.append("email", event.email);
      formData.append("services", event.services.join(", "));
      formData.append("date", event.start);
      formik.values.fileInputs.forEach((attachment) => {
        formData.append("attachments", attachment.content);
      });

      await fetch("https://woofgrooming.wl.r.appspot.com/email/send", {
        method: "POST",
        body: formData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day === 0 || day === 1;
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      fileInputs: [],
      phone: "",
      remarks: "",
    },
    validateOnMount: true,
    validationSchema: appointmentValidation,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const eventStart = moment(
          `${data.date} ${confirmedTimeslot}`,
          "YYYY-MM-DD HH:mm"
        );
        const eventEnd = eventStart
          .clone()
          .add(servicesDetails.duration, "minutes");

        const event = {
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone,
          remarks: values.remarks,
          start: eventStart,
          end: eventEnd,
          services: servicesDetails.services,
        };

        const res = await addEvent(event);
        if (res.status === "success") {
          handleSendEmail(event);
        }
        setIsDateSelected(false);
        setActiveDate(null);
        setResponseStatus(res.status);
        setAlertDismissed(false);
        setSelectedTimeslotId(null);
        setConfirmedTimeslot(null);
        setChosenServices([]);
        resetForm();
        setSubmitting(false);
      } catch (error) {
        setResponseStatus("failed");
      }
    },
  });

  const handleDateChange = async (e) => {
    const date = moment(e).format("YYYY-MM-DD");
    setActiveDate(e);
    try {
      setIsDateSelected(true);
      setIsLoading(true);
      const data = await fetchAvailableSlots(date);
      setData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackButtonPress = () => {
    setIsDateSelected(false);
    setActiveDate(null);
    setSelectedTimeslotId(null);
    setConfirmedTimeslot(null);
    setChosenServices([]);
    formik.resetForm();
  };

  return (
    <div className="calendar-screen-container" id="calendar">
      {alertDismissed === true ? null : responseStatus === "success" ? (
        <SuccessAlert
          message={
            "Your event has been succesfully booked. You'll receive the invitation by email. Thank you !"
          }
          dismissed={alertDismissed}
          setDismissed={setAlertDismissed}
        />
      ) : (
        <FailAlert
          message={"Sorry, something went wrong. Please try again."}
          dismissed={alertDismissed}
          setDismissed={setAlertDismissed}
        />
      )}
      <h2 className="calendar-screen-title">Book an Appointment</h2>
      <div
        className="calendar-main-container"
        style={isDateSelected ? { width: "100%" } : null}
      >
        <div
          className="calendar-description"
          style={isDateSelected ? { width: "40%" } : null}
        >
          {isDateSelected ? (
            <div className="arrow-container" onClick={handleBackButtonPress}>
              <FaArrowLeft />
            </div>
          ) : null}
          <div className="description-logo-container">
            <img src={logo} alt="logoo" />
          </div>
          <div className="description-texts-container">
            <p className="description-domain">Woofgrooming.net</p>
            {confirmedTimeslot ? (
              <div className="appointment-details">
                <div className="detail-container">
                  <div className="detail-icon">
                    <MdOutlineCalendarMonth />
                  </div>
                  <p className="detail-text">
                    {moment(data.date).format("dddd, MMMM D")}
                  </p>
                </div>
                <div className="detail-container">
                  <div className="detail-icon">
                    <CiClock2 />
                  </div>
                  <p className="detail-text">
                    {moment(confirmedTimeslot, "HH:mm").format("h:mm a")}
                  </p>
                </div>
                <div className="detail-container">
                  <div className="detail-icon">
                    <LiaGlobeAmericasSolid />
                  </div>
                  <p className="detail-text">{data.hostTimezone}</p>
                </div>
                <div className="detail-container">
                  <div className="detail-icon">
                    <GiDuration />
                  </div>
                  <p className="detail-text">
                    <span>{servicesDetails.duration} min</span>
                  </p>
                </div>
                <div className="detail-container">
                  <div className="detail-icon">
                    <IoPricetagOutline />
                  </div>
                  <p className="detail-text">
                    From <span>${servicesDetails.price}</span>
                  </p>
                </div>
              </div>
            ) : (
              <p className="description-text">
                Book your pet's grooming appointment hassle-free using our
                convenient online calendar. Choose a time that suits your
                schedule, and arrive a few minutes early for a prompt start. If
                you have specific requests or multiple pets, let us know in
                advance. Check out our grooming packages for comprehensive care,
                and feel free to ask for aftercare tips during your appointment.
                We look forward to pampering your furry friends with expert care
                and attention
              </p>
            )}
          </div>
        </div>

        {/* Appointment Form */}
        {confirmedTimeslot != null ? (
          <div className="appointment-information-container">
            <p className="appointment-information-title">Appointment Details</p>
            <form onSubmit={formik.handleSubmit}>
              {/* Firstname and Lastname Inputs */}
              <div className="name-inputs">
                <div className="name-input-container">
                  <label htmlFor="firstname" className="input-label">
                    First Name*
                  </label>
                  <input
                    type="text"
                    className="name-input"
                    name="firstname"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.firstname && formik.touched.firstname && (
                    <div className="error-message">
                      {formik.errors.firstname}
                    </div>
                  )}
                </div>

                <div className="name-input-container">
                  <label htmlFor="lastname" className="input-label">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    className="name-input"
                    name="lastname"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.lastname && formik.touched.lastname && (
                    <div className="error-message">
                      {formik.errors.lastname}
                    </div>
                  )}
                </div>
              </div>

              {/* Email Input */}
              <div className="input-container">
                <label htmlFor="email" className="input-label">
                  Email*
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="input"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="error-message">{formik.errors.email}</div>
                )}
              </div>

              {/* Phone Number Input */}
              <div className="input-container">
                <label htmlFor="phone" className="input-label">
                  Phone Number
                </label>
                <PatternFormat
                  format="+1 (###) ###-####"
                  name="phone"
                  className="input"
                  type="text"
                  value={formik.values.phone}
                  allowEmptyFormatting
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="error-message">{formik.errors.phone}</div>
                )}
              </div>

              {/* Services Tags */}

              <p className="calendar-services-title">Services*</p>
              <div className="calendar-services-container">
                {services.map((service) => {
                  const isActive = chosenServices.includes(service.id);
                  const disabled =
                    servicesDetails.duration + service.duration > availableTime;
                  return (
                    <ServiceTag
                      key={service.id}
                      service={service}
                      isActive={isActive}
                      setChosenServices={setChosenServices}
                      servicesDetails={servicesDetails}
                      availableTime={availableTime}
                      chosenServices={chosenServices}
                      confirmedTimeslot={confirmedTimeslot}
                      disabled={disabled}
                    />
                  );
                })}
              </div>

              {/* Attachments */}

              <div className="file-input-container">
                <label htmlFor="fileInputs" className="file-input-label">
                  Upload Images
                </label>
                <p className="file-upload-instruction">
                  Upload the pictures of your pet*
                </p>
                <input
                  type="file"
                  id="fileInputs"
                  name="fileInputs"
                  style={{ display: "none" }}
                  multiple
                  onChange={(event) => {
                    const files = Array.from(event.currentTarget.files);

                    if (files.length > 0) {
                      const newAttachments = [...formik.values.fileInputs];
                      Array.from(files).forEach((file) => {
                        newAttachments.push({
                          filename: file.name,
                          content: file,
                        });
                      });
                      formik.setFieldValue("fileInputs", newAttachments);
                    }
                  }}
                />
                <div className="all-files-container">
                  {formik.values.fileInputs.map((file, index) => {
                    return (
                      <div className="file-container" key={index}>
                        <img
                          src={URL.createObjectURL(file.content)}
                          alt={file.filename}
                          className="file-image"
                        />
                        <div
                          className="delete"
                          onClick={() => {
                            const updatedAttachments = [
                              ...formik.values.fileInputs,
                            ];
                            updatedAttachments.splice(index, 1);
                            formik.setFieldValue(
                              "fileInputs",
                              updatedAttachments
                            );
                          }}
                        >
                          <RiCloseCircleFill size={25} color="#d53636" />
                        </div>
                      </div>
                    );
                  })}
                </div>
                {formik.errors.fileInputs && formik.touched.fileInputs && (
                  <div className="error-message">
                    {formik.errors.fileInputs}
                  </div>
                )}
              </div>

              {/* Additional Remarks */}

              <div className="moreabout-input-container">
                <label htmlFor="moreabout" className="input-label">
                  Additional remarks
                </label>
                <textarea
                  type="text"
                  className="moreabout-input"
                  name="remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="form-button-container">
                <button
                  type="submit"
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    chosenServices.length === 0
                  }
                  className={
                    formik.isValid ? "form-button valid" : "form-button"
                  }
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="calendar-and-slot-container">
            {/* Calendar and Tiemslots */}
            <div
              className={
                isDateSelected
                  ? "calendar-container calendar-active"
                  : "calendar-container"
              }
              style={isDateSelected ? { width: "40%" } : null}
            >
              <Calendar
                value={activeDate}
                minDate={new Date()}
                showNavigation={true}
                showNeighboringMonth={false}
                onChange={handleDateChange}
                tileDisabled={({date}) => isWeekday(date)}
              />
            </div>
            <div
              className={
                isDateSelected
                  ? "calendar-timeslots-container calendar-timeslots-active"
                  : "calendar-timeslots-container"
              }
              style={
                isDateSelected
                  ? {
                      width: "20%",
                      borderLeft: "1px solid rgba(134, 134, 134, 0.302)",
                    }
                  : null
              }
            >
              {isLoading ? (
                <ReactLoading
                  type={"bars"}
                  color={"#ff9f68"}
                  height={"70px"}
                  width={"70px"}
                />
              ) : (
                <>
                  <div
                    className="calendar-timeslots-date-container"
                    style={isDateSelected ? { width: "100%" } : null}
                  >
                    <p className="calendar-timeslots-date">
                      {moment(data.date).format("dddd, MMMM D")}
                    </p>
                  </div>
                  <div className="timeslots">
                    {Object.keys(data).length > 0 ? (
                      data.availableSlots.length > 0 ? (
                        data.availableSlots.map((slot, index) => {
                          return (
                            <Timeslot
                              key={index}
                              slot={slot}
                              buttonId={index}
                              date={data.date}
                              hostTimezone={data.hostTimezone}
                              selectedTimeslotId={selectedTimeslotId}
                              setSelectedTimeslotId={setSelectedTimeslotId}
                              setConfirmedTimeslot={setConfirmedTimeslot}
                              setAvailableTime={setAvailableTime}
                              servicesDetails={servicesDetails}
                            />
                          );
                        })
                      ) : (
                        <p className="notimeslot-text">
                          There is not any available timeslot for the chosen
                          date. Please try to choose another date.
                        </p>
                      )
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarScreen;
