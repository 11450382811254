import Carousel from "../../components/carousel/Carousel";
import "./services.scss";
import { FaBath } from "react-icons/fa";
import { GiHairStrands } from "react-icons/gi";
import { MdOutlinePets } from "react-icons/md";
import { LiaToothSolid } from "react-icons/lia";
import { GiBarbedNails } from "react-icons/gi";
import { RiBrush3Line } from "react-icons/ri";
import { FaDog } from "react-icons/fa6";
import { MdOutlineBathroom } from "react-icons/md";
import { MdHearing } from "react-icons/md";

const services = [
  {
    id: 1,
    icon: <MdHearing size={70} color="#70635b" />,
    name: "Ear Cleaning",
    price: 15,
    description:
      "Treat your pet to the ultimate comfort with our Gentle Ear Cleaning Service, where we delicately remove wax and debris, ensuring clean and healthy ears for a happy and contented furry friend.",
    duration: 15,
  },
  {
    id: 2,
    icon: <LiaToothSolid size={70} color="#70635b" />,
    name: "Teeth Brushing",
    price: 20,
    description:
      "Elevate your pet's dental hygiene with our Teeth Brushing Service, as our expert groomers work to keep their smile bright, ensuring not just clean teeth but a happy and healthy grin.",
    duration: 15,
  },
  {
    id: 3,
    icon: <MdOutlinePets size={70} color="#70635b" />,
    name: "Nail Clipping",
    price: 12,
    description:
      "Give your pet the paw-some care they deserve with our Nail Clipping Service, where our skilled groomers provide gentle and precise nail trims, ensuring your furry friend's paws are comfortable and well-maintained.",
    duration: 15,
  },
  {
    id: 4,
    icon: <GiBarbedNails size={70} color="#70635b" />,
    name: "Nail Grinding",
    price: 15,
    description:
      "Perfect those paws with our Nail Grinding Service, where skilled groomers use gentle techniques to achieve smooth and well-maintained nails for your pet, ensuring both comfort and style.",
    duration: 35,
  },
  {
    id: 5,
    icon: <RiBrush3Line size={70} color="#70635b" />,
    name: "De-Shedding",
    price: 30,
    description:
      "Say goodbye to excessive shedding with our De-shedding Treatment, where our expert groomers use specialized techniques to reduce shedding and leave your pet with a healthier, more lustrous coat.",
    duration: 15,
  },
  {
    id: 6,
    icon: <FaDog size={70} color="#70635b" />,
    name: "Anal Glands Expression and Bath",
    price: 30,
    description:
      "Indulge your pet in a spa-like experience with our Anal Gland Expression and Bath Combo, where expert groomers provide a thorough anal gland expression alongside a refreshing bath.",
    duration: 15,
  },
  {
    id: 7,
    icon: <MdOutlineBathroom size={70} color="#70635b" />,
    name: "Bath",
    price: 50,
    description:
      "Refresh and rejuvenate your pet with our luxurious Bath Service, where our expert groomers use premium products to cleanse and pamper, leaving your furry companion feeling revitalized and irresistibly soft.",
    duration: 90,
  },
  {
    id: 8,
    icon: <GiHairStrands size={70} color="#70635b" />,
    name: "Haircut and Bath",
    price: 80,
    description:
      "Transform your pet's look with our comprehensive Haircut Service, encompassing a full-body and face makeover, or opt for a tailored touch-up with our Face, Feet, and Tail Trims, ensuring your furry friend looks and feels their absolute best.",
    duration: 120,
  },
  {
    id: 9,
    icon: <FaBath size={70} color="#70635b" />,
    name: "Bath and Hygienic Cut",
    price: 60,
    description:
      "Pamper your pet with our Deluxe Bath Package, featuring a hygienic cut for areas like under the paws and tummy, precise nail clipping, and gentle ear cleaning. Elevate their grooming experience for a clean, comfortable, and stylish furry friend.",
    duration: 90,
  },
];

const Services = () => {
  return (
    <div className="services-main-container" id="services">
      <h2 className="services-title">Services</h2>
      <Carousel slides={services} />
    </div>
  );
};

export default Services;
