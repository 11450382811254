import "./footer.scss";
import logo from "../../assets/icons/logo-min.png";
import { Link } from "react-scroll";
import { MdOutlinePhone } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { IoLocationOutline } from "react-icons/io5";

const Footer = () => {
  return (
    <div className="footer-main-container" id="footer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 320"
        width={"100%"}
      >
        <path
          fill="#ffffff"
          fillOpacity="1"
          d="M0,320L48,314.7C96,309,192,299,288,256C384,213,480,139,576,128C672,117,768,171,864,170.7C960,171,1056,117,1152,106.7C1248,96,1344,128,1392,144L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>

      <footer>
        <div className="footer-left">
          <div className="logo-container">
            <img src={logo} alt="picoflogo" />
          </div>
          <p className="footer-slogan">
            Pet Perfection: Where Grooming Meets Comfort
          </p>
          <p className="copyright">
            &copy; {new Date().getFullYear()} Woofgrooming. All rights reserved
          </p>
        </div>
        <div className="footer-right">
          <div className="quick-links">
            <p className="quick-link-title">Quick Links</p>
            <Link to="home" spy={true} smooth={true} className="link">
              Home
            </Link>
            <Link to="about" spy={true} smooth={true} className="link">
              About
            </Link>
            <Link to="getstarted" spy={true} smooth={true} className="link">
              Get Started
            </Link>
            <Link to="services" spy={true} smooth={true} className="link">
              Services
            </Link>
            <Link to="calendar" spy={true} smooth={true} className="link">
              Make Appointment
            </Link>
          </div>
          <div className="contact-us">
            <p className="contact-title">Contact Us</p>
            <div className="phone">
              <MdOutlinePhone /> +1 (818) 928 8008
            </div>
            <div className="phone">
              <GoMail /> contact@woofgrooming.net
            </div>
            <div className="phone">
              <IoLocationOutline /> 2950 Honolulu Ave La Crescenta <br /> CA
              91214 United States
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
