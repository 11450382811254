import "./home.scss";
import { Link } from "react-scroll";
import LazyLoad from "react-lazyload";

const Home = () => {
  return (
    <div className="home-main-container" id="home">
      <div className="home-sections-container">
        <div className="left-section">
          <p className="left-heading">
            We Take Care Of<br></br> <span>Your Pets</span>
          </p>
          <p className="left-text1">
            Step into a world of WoofGrooming, where we make your furry friends
            look and feel their best. Book an appointment and let us transform
            your pet's grooming experience into a spa day they'll love!
          </p>
          <div className="booking-button">
            <Link
              to="calendar"
              spy={true}
              smooth={true}
              className="button-text"
            >
              Make an <br /> Appointment
            </Link>
          </div>
        </div>
        <div className="right-section">
          <LazyLoad>
            <img
              src="https://ik.imagekit.io/zzirqv7cp/Woofgrooming%20Web/dog-min.png?updatedAt=1701878866830"
              alt="cat"
              loading="lazy"
            />
          </LazyLoad>
        </div>
      </div>

      <div className="bottom-curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 316"
          width={"100%"}
        >
          <path
            fill="white"
            fillOpacity="1"
            d="M0,0L48,37.3C96,75,192,149,288,149.3C384,149,480,75,576,64C672,53,768,107,864,128C960,149,1056,139,1152,138.7C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default Home;
