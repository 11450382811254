import "./servicecard.scss";
import { Link } from "react-scroll";

const ServiceCard = ({ service }) => {
  return (
    <div className="servicecard-container">
      <div className="icon-container">{service.icon}</div>
      <h3 className="servicecard-title">{service.name}</h3>
      <p className="servicecard-description">{service.description}</p>
      <p className="servicecard-price">
        From <span className="span-currency">$</span>
        <span className="span-price">{service.price}</span>
      </p>
      <Link to="calendar" spy={true} smooth={true}>
        <div className="servicecard-button">
          <p> Make An Appointment</p>
        </div>
      </Link>
    </div>
  );
};

export default ServiceCard;
