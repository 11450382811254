import "./getstarted.scss";

const GetStarted = () => {
  return (
    <div className="getstarted-global-container" id="getstarted">
      <div className="top-curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          width={"100%"}
        >
          <path
            fill="white"
            fillOpacity="1"
            d="M0,64L48,101.3C96,139,192,213,288,218.7C384,224,480,160,576,122.7C672,85,768,75,864,80C960,85,1056,107,1152,106.7C1248,107,1344,85,1392,74.7L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
          ></path>
        </svg>
      </div>
      <div className="getstarted-main-container">
        <h2 className="getstarted-title">Get Started</h2>
        <div className="first-section">
          <div className="left-container">
            <img
              src="https://ik.imagekit.io/zzirqv7cp/Woofgrooming%20Web/calendar.jpg?updatedAt=1701878866830"
              alt="calendar"
              loading="lazy"
            />
          </div>
          <div className="right-container">
            <h3 className="text-title">1.Schedule Your Appointment</h3>
            <ul>
              <li>
                When it's time to pamper your furry friend, the first step is to
                schedule an appointment with Woofgrooming. We've made it easy
                for you to select the grooming service that perfectly suits your
                pet's needs. Whether it's a refreshing bath, a stylish haircut,
                or a nail trim, you can choose the service that will keep your
                pet looking and feeling their best.
              </li>
              <li>
                Next, you'll be able to browse through our user-friendly
                calendar system to select a date and time that fits your busy
                schedule. We understand that convenience is key, so we offer
                flexible time slots to accommodate your preferences.
              </li>
              <li>
                To confirm your appointment and keep you updated, we'll need
                some basic information from you. You'll provide your name, phone
                number, and email address. This information ensures that we can
                reach out to you for appointment reminders and important
                updates, ensuring a smooth and hassle-free experience.
              </li>
              <li>
                Once you've selected the grooming service, date, and time, and
                provided your contact information, simply click the "Submit"
                button. Your appointment request will be sent to our team, and
                you will promptly receive a confirmation email, confirming your
                appointment with Woofgrooming.
              </li>
            </ul>
          </div>
        </div>
        <div className="second-section">
          <div className="left-container">
            <h3 className="text-title">2.Visit Our Salon</h3>
            <ul>
              <li>
                Once you've scheduled your appointment with Woofgrooming, it's
                time for the next step – visiting our dedicated pet grooming
                salon. At our salon, we've created an environment that
                prioritizes your pet's comfort and well-being.
              </li>
              <li>
                Our team of experienced grooming professionals is enthusiastic
                about welcoming you and your furry companion. We are committed
                to ensuring that your pet receives the highest level of care,
                leaving our salon looking and feeling their very best.
              </li>
              <li>
                When you step into our salon, you'll immediately sense the warm
                and inviting atmosphere we've carefully crafted. We place a
                strong emphasis on creating a stress-free environment, ensuring
                that every pet feels at ease during their visit. Our skilled
                professionals are dedicated to providing a safe and comfortable
                space for your pet
              </li>
              <li>
                Our friendly and knowledgeable staff will be there to greet you
                and guide you through the grooming process. Feel free to share
                any specific requests or concerns you may have about your pet's
                grooming. We're here to ensure that your pet's visit is tailored
                to their unique needs.
              </li>
              <li>
                During your visit, our grooming experts will conduct a
                comprehensive assessment of your pet's coat condition, size, and
                any specific requirements. This assessment enables us to provide
                you with a personalized quote for the grooming service your pet
                needs. Please rest assured that your pet's safety and well-being
                are of paramount importance to us. We maintain rigorous
                cleanliness and hygiene standards in our salon, and each pet is
                handled with the utmost care and affection, just like they're a
                part of our own family.
              </li>
            </ul>
          </div>
          <div className="right-container">
            <img
              src="https://ik.imagekit.io/zzirqv7cp/Woofgrooming%20Web/grooming.jpg?updatedAt=1701878866830"
              alt="grooming"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="bottom-curve">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 318"
          width={"100%"}
        >
          <path
            fill="white"
            fillOpacity="1"
            d="M0,32L48,48C96,64,192,96,288,106.7C384,117,480,107,576,96C672,85,768,75,864,96C960,117,1056,171,1152,192C1248,213,1344,203,1392,197.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default GetStarted;
