import "./successalert.scss";
import { MdClose } from "react-icons/md";

const SuccessAlert = ({ message, dismissed, setDismissed }) => {
  const handleBackdropClick = () => {
    setDismissed(true);
  };

  const handleCloseClick = () => {
    setDismissed(true);
  };
  return !dismissed ? (
    <div className="success-alert" onClick={handleBackdropClick}>
      <MdClose className="close-icon" onClick={handleCloseClick} />
      <p>{message}</p>
      <button className="dismiss-button" onClick={handleCloseClick}>
        Dismiss
      </button>
    </div>
  ) : null;
};

export default SuccessAlert;
