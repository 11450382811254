import "./timeslot.scss";
import moment from "moment-timezone";

const Timeslot = ({
  slot,
  buttonId,
  selectedTimeslotId,
  date,
  hostTimezone,
  setSelectedTimeslotId,
  setConfirmedTimeslot,
  setAvailableTime,
}) => {

  const fetchAvailableTime = async () => {
    const oDate = moment(date);
    const dateWithTime = oDate.add(moment.duration(slot.slot));
    const formattedDateTime = dateWithTime.format(
      "dddd, MMMM D H:mm",
      hostTimezone
    );
    const settings = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dateTime: formattedDateTime,
      }),
    };

    try {
      const response = await fetch(
        "https://woofgrooming.wl.r.appspot.com/calendar/availabletime",
        settings
      );
      const data = await response.json();
      setAvailableTime(data.time);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmSlot = (slot) => {
    setConfirmedTimeslot(slot);
    fetchAvailableTime();
  };

 
  return (
    <div className="timeslot-container">
      <div
        className="timeslot"
        style={{ width: selectedTimeslotId === buttonId ? "48%" : "100%" }}
        onClick={() => setSelectedTimeslotId(buttonId)}
      >
        <p className="timeslot-text">{slot.slot}</p>
      </div>
      <div
        className="confirm-container"
        style={{ width: selectedTimeslotId === buttonId ? "48%" : "0px" }}
        onClick={() => handleConfirmSlot(slot.slot)}
      >
        <p
          style={{
            display: selectedTimeslotId === buttonId ? "block" : "none",
          }}
          className="confirm-text"
        >
          Confirm
        </p>
      </div>
    </div>
  );
};

export default Timeslot;
