import "./about.scss";

const About = () => {
  return (
    <div className="about-main-container" id="about">
      <h2 className="about-title">About Us</h2>
      <p className="about-main-text">
        At Woofgrooming, we're more than just a pet grooming company; we're your
        pet's second best friend. As passionate animal lovers, we understand the
        unique bond you share with your furry companions. That's why we've made
        it our mission to pamper your pets with love, care, and exceptional
        grooming services. Our team of professional groomers is dedicated to
        ensuring that every pet that walks through our doors leaves looking and
        feeling their best. We take pride in the trust our clients place in us
        and the responsibility we hold to keep your pets happy and healthy.
      </p>
      <div className="about-sections-container">
        <div className="section-container">
          <p className="section-number">1</p>
          <p className="section-text">
            <span>Experienced and Caring Staff</span> <br /> <br />
            Our groomers are not only experts in their field but also genuine
            animal lovers. We treat your pets like our own, providing a
            stress-free and comfortable grooming experience.
          </p>
        </div>
        <div className="section-container">
          <p className="section-number">2</p>
          <p className="section-text">
            <span>Tailored Grooming Services</span> <br /> <br />
            We understand that every pet is unique. Whether it's a
            breed-specific cut or a special treatment for sensitive skin, we
            provide personalized grooming services to suit your pet's individual
            needs.
          </p>
        </div>
        <div className="section-container">
          <p className="section-number">3</p>
          <p className="section-text">
            <span>Clean and Safe Environment</span> <br /> <br />
            We maintain a clean and safe grooming environment, ensuring your
            pet's well-being throughout their visit.
          </p>
        </div>
        <div className="section-container">
          <p className="section-number">4</p>
          <p className="section-text">
            <span>Top-Quality Products</span> <br /> <br />
            We use only the finest grooming products, making sure your pet's
            coat is healthy, shiny, and free from irritations.
          </p>
        </div>
        <div className="section-container">
          <p className="section-number">5</p>
          <p className="section-text">
            <span>Convenient Appointments</span> <br /> <br />
            With flexible scheduling, we make it easy for you to book a grooming
            session at your convenience.
          </p>
        </div>
        <div className="section-container">
          <p className="section-number">6</p>
          <p className="section-text">
            <span>A Happy Place for Your Pet</span> <br /> <br />
            Our facility is designed to be a home away from home for your pet.
            They'll enjoy their time here, and you'll be delighted with the
            results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
