export const fetchAvailableSlots = async (date) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      date: date,
    }),
  };
  try {
    const response = await fetch(
      "https://woofgrooming.wl.r.appspot.com/calendar/availableslots",
      // "http://localhost:2000/calendar/availableslots",
      settings
    );
    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addEvent = async (event) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  };
  try {
    const res = await fetch(
      "https://woofgrooming.wl.r.appspot.com/calendar/addevent",
      // "http://localhost:2000/calendar/addevent",
      settings
    );
    const data = await res.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
