import "./app.scss";
import Header from "./components/header/Header";
import Home from "./screens/home/Home";
import About from "./screens/about/About";
import GetStarted from "./screens/getstarted/GetStarted";
import Services from "./screens/services/Services";
import Calendar from "./screens/calendar/Calendar";
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <div className="app-main-container">
      <Header />
      <Home />
      <About />
      <GetStarted />
      <Services />
      <Calendar />
      <Footer />
    </div>
  );
};

export default App;
