import { useEffect } from "react";

export function OutsideClick(ref1, ref2, action) {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        ref1.current &&
        !ref1.current.contains(event.target) &&
        ref2.current &&
        !ref2.current.contains(event.target)
      ) {
        action();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ref1, ref2]);
}
